.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #ECEFF1;

}
/* .table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: #ECEFF1;

} */

.custom-bg-color {
    background-color: green;
}


.global-margin {
    margin-left: 350px;
    margin-right: 350px;
}


.task-row td {
  background-color: white; /* Adjust background color as needed */
}
